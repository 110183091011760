@media (prefers-color-scheme: light) {
     :root {
        --notes-bg-color: #fff;
        --notes-border-default: #d0d7de;
        --notes-text-bg-yellow: #fbf2da;
        --notes-text-color-yellow: #ca902e;
    }
}

@media (prefers-color-scheme: dark) {
     :root {
        --notes-bg-color: #0d1117;
        --notes-border-default: #30363d;
        --notes-text-bg-yellow: #564328;
        --notes-text-color-yellow: #c99849;
    }
    .containerBox .note_title .note_close_button svg,
    .copy-btn svg {
        fill: var(--color-fg-default, #c9d1d9);
    }
    /* background: linear-gradient(90deg, rgba(255, 255, 255, 0.12) 25%, rgba(255, 255, 255, 0.18) 37%, rgba(255, 255, 255, 0.12) 63%);
    background: rgba(255, 255, 255, 0.12); */
    .ant-skeleton.ant-skeleton-active .ant-skeleton-title::after,
    .ant-skeleton.ant-skeleton-active .ant-skeleton-paragraph>li::after,
    .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar::after,
    .ant-skeleton.ant-skeleton-active .ant-skeleton-button::after,
    .ant-skeleton.ant-skeleton-active .ant-skeleton-input::after,
    .ant-skeleton.ant-skeleton-active .ant-skeleton-image::after {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.12) 25%, rgba(255, 255, 255, 0.18) 37%, rgba(255, 255, 255, 0.12) 63%) !important;
    }
    .ant-skeleton-title,
    .ant-skeleton-paragraph li {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.12) 25%, rgba(255, 255, 255, 0.18) 37%, rgba(255, 255, 255, 0.12) 63%) !important;
    }
    .copy-btn {
        background: none;
        border-color: var(--color-border-muted);
    }
    .w-heatmap text {
        /* background-color: rgb(0, 0, 0); */
        /* color: rgb(255, 255, 255); */
        fill: currentColor;
        color: rgb(136, 136, 136);
    }
}

@media (width <=600px) {
    #root .containerBox {
        border: none;
    }
}

@media (width >600px) {
    #root .notes_box {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
    .containerBox,
    .post_list {
        width: 650px;
    }
}

body {
    padding: 0;
    margin: 0;
    height: 100vh;
    /* background-color: var(--color-canvas-default, red); */
}

.notes_box {
    height: 100vh;
}

a {
    word-wrap: break-word;
}

img {
    width: 100%;
    max-width: 800px;
    margin-bottom: 16px;
}

article {
    margin-bottom: 2rem;
}

article a::after {
    /* text-decoration: underline !important; */
    content: "↗";
}

.markdown-body {
    /* margin: 0 auto !important; */
}

.post_list {
    /* margin-top: 1rem; */
    padding-top: 2rem;
}

.post_list ul {
    margin: 0 !important;
    padding: 0 !important;
}

.post_list li {
    list-style: none;
    margin-bottom: 1.2rem;
}

.post_list h2 {
    font-size: 1.2rem !important;
    padding-bottom: 0 !important;
    margin-bottom: 0.4rem !important;
    border-bottom: none !important;
}

.post_list a {
    text-decoration: none;
    color: #333;
}

.post_list time,
.article_bottom {
    font-size: 0.9rem;
    color: #666;
}

.containerBox .article_bottom {
    margin-bottom: 1rem;
    display: flex;
}

.article_bottom time {
    margin-right: 1rem;
}

.containerBox {
    position: sticky;
    padding-bottom: 1rem;
    overflow-y: auto;
    /* flex: 0 0 auto; */
    border-right: 1px solid var(--color-border-default);
    /* border-left: 1px solid var(--color-border-default); */
}

.containerBox,
.post_list {
    margin: 0 auto;
    /* width: 38rem; */
    /* width: 650px; */
    padding-left: 1.4rem;
    padding-right: 1.4rem;
}

.containerBox .note_title {
    display: flex;
    position: fixed;
    flex-direction: row;
    align-items: center;
    top: 0;
    padding: 60px 0 0 0;
    width: 40px;
    height: 100%;
    writing-mode: vertical-lr;
    background-color: var(--color-canvas-default);
    z-index: 999;
}

.containerBox .note_title p {
    cursor: pointer;
    flex: 0.9;
}

.containerBox .note_title * {
    margin: 0;
    padding: 4px;
}

.containerBox .note_title .note_close_button {
    position: absolute;
    bottom: 8px;
    cursor: pointer;
}

.containerBox .note_title p:hover {
    color: var(--color-accent-fg);
}

.containerBox .note_title .note_close_button:hover,
.containerBox .note_title p:active,
.my_link:active {
    opacity: 0.6;
}

.notes {
    display: flex;
    width: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    flex-grow: 1;
}

.containerBox {
    padding-bottom: 1.4rem;
}

.overlay {
    box-shadow: 0px 0px 15px 3px rgb(0 0 0 / 10%);
}

.nav {
    /* background-color: #fff; */
    /* color: #000; */
    display: flex;
    height: 3rem;
    position: sticky;
    top: 0;
    border-bottom: 1px solid var(--color-border-defaultABC, var(--notes-border-default));
    /* margin-bottom: 1.4rem !important; */
    padding: 0 1rem;
    z-index: 999;
}

.nav ul {
    display: flex;
    padding: 0;
    height: 100%;
    margin: 0;
}

.nav li {
    /* position: relative; */
    margin-left: 0.4rem;
    list-style: none;
    margin-top: 0em !important;
}

.nav a {
    text-decoration: none;
    padding: 0.4rem;
}

.nav a:hover {
    text-decoration: underline;
}

.nav header {
    position: relative;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    /* width: 100%; */
    /* margin-left: 2rem; */
}

.nav header,
.nav ul {
    line-height: 3rem;
}

.nav header img {
    width: 1.4rem;
}

.nav header img {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
}

.backLinks {
    border: 1px solid var(--color-border-default, var(--notes-border-default));
    padding: 0.6rem;
    background-color: var(--color-canvas-subtle);
    border-radius: 2px;
}

.backLinks header {
    margin-bottom: 0.4rem;
    font-weight: bold;
}

.nav a,
.post_list a {
    color: var(--color-fg-default) !important;
}

.my_link {
    background-color: transparent;
    color: var(--color-accent-fg);
    text-decoration: none;
    cursor: pointer;
}

.my_link:hover,
.backLinks a:hover,
.post_list a:hover {
    text-decoration: underline;
}

.unknown_card {
    opacity: 0.6;
    /* 禁止点击 */
    pointer-events: none;
}

.unknown_card::after {
    /* text-decoration: underline !important; */
    content: "";
}

.loading {
    /* position: relative; */
    /* opacity: 0.6; */
    width: 650px;
    padding: 0 1rem;
}

.footer {
    text-align: center;
    color: var(--color-fg-subtle);
}

.containerBox article pre {
    /* background-color: var(--color-canvas-default, var(--notes-bg-color));
    padding: 0;
    margin: 0; */
}

.markdown-body #player {
    margin: 0;
}

.music {
    text-align: center;
    margin: 1rem 0;
}

.music iframe {
    max-width: 28rem;
}

.highlight_bg {
    background-color: var(--notes-text-bg-yellow);
}

.highlight_color {
    color: var(--notes-text-color-yellow);
}

.listBox {
    display: flex;
}

.listBullet {
    line-height: 1.3em;
}

.numberListBullet::before {
    content: attr(data-before);
    margin-right: 0.2em;
    /* font-size: 1.57em; */
}

.listBullet::before {
    content: "•";
    margin-right: 0.2em;
    font-size: 1.57em;
}

.markdown-body pre {
    background: #303030 !important;
}

.markdown-body table p {
    margin-bottom: 0;
}

.notes article ol>li {
    list-style: decimal;
}

.notes article ul>li {
    list-style: disc;
}

.markdown-body .nav ul {
    padding-left: 0.4rem;
}

.copy-btn {
    /* background: none;
    font-size: 0.6rem;
    padding: 4px 8px 2px 8px;
    border-radius: 2px;
    border: 1px solid #fff;
    cursor: pointer; */
}

.copy-btn:hover {
    /* text-decoration: underline; */
}

.markdown-body .task-list-item {
    display: flex;
    margin: 16px 0;
}

.calendarHeatmap {
    padding: 2rem;
    min-width: 98%;
}

svg.w-heatmap rect {
    cursor: auto !important;
}